import swal from 'sweetalert2'

export default () => {
    $( '.toggle-leaderboard' ).click( () => {
        let $active = $( '.leaderboard-active' )
        let $inactive = $( '.leaderboard-inactive' )
        $active.removeClass( 'leaderboard-active' ).addClass( 'leaderboard-inactive' )
        $inactive.removeClass( 'leaderboard-inactive' ).addClass( 'leaderboard-active' )
    })

    // Could be more elegant eventually
    const isBrews = $( 'body' ).attr('id') === 'Brews&Clues'
    const isGoTrivia = $( 'body' ).attr('id') === 'GoTrivia'
    const popupData = $( '#leaderboard' ).data( 'popupData' )

    if($( '#leaderboard' ).data('isdemo')) {
        setTimeout(() => {
            swal.fire({
                customClass: 'answer-feedback-modal',
                html: '<h1>You crushed it!</h1><img style="max-width:100%;" src="/correct" /><p style="line-height: 29px;font-size: 18px; font-weight:600;">The only thing left to do is plan your first Brews & Clues tour!</p><p style="line-height: 29px;font-size: 18px;">We have locations all over the US! Click the button below to explore.</p>',
                confirmButtonText: 'Tour Locations',
                showCancelButton: false,
            }).then((result) => {
                if(result.isConfirmed && isBrews) window.location.href = 'https://www.brewsandclues.com/locations/'
                if(result.isConfirmed && isGoTrivia) window.location.href = 'https://www.gotrivia.com/all-locations/'
            })
        }, 3000)
    } else if(popupData) {
        setTimeout(() => {
            const fallbackUrl = isBrews ? 'https://www.brewsandclues.com/locations/' : 'https://www.gotrivia.com/all-locations/'
            swal.fire({
                customClass: 'answer-feedback-modal',
                html: `
                    <h1>${popupData.headline}</h1>
                    ${popupData.top_content}<br/>
                    <a href="${popupData.referral_tour.product_url || fallbackUrl}" target="_blank"><img style="max-width:75%;margin-top: 20px;margin-bottom:20px;" src="${popupData.referral_tour.img}" /></a>
                    <p style="line-height: 29px;font-size: 16px; margin-bottom:5px;">Discount code:</p>
                    <p style="line-height: 29px;font-size: 45px; font-weight: 500;font-family: 'bebas-neue'; margin-bottom: 20px;">${popupData.discount_code}</p>
                    ${popupData.content}<br/>
                    <a class="btn btn-success btn-lg w-100" style="margin-top: 30px; margin-bottom: 10px; color: black; font-weight: 500;" href="${popupData.referral_tour.product_url || fallbackUrl}" target="_blank">${popupData.button_text || 'Buy It Now'}</a>
                `,
                showConfirmButton: false,
                showCancelButton: false
            })
        }, 4000)
    }
}