<template lang="pug">
    .col-md-8.text-center.tour-content

        h1 What did you think?
        p.mb-4 Before we take you to the leaderboard—let us know what you thought about your tour today.

        p.mb-3
            strong How would you rate your overall experience?
            br
            i.fa.fa-star(
                v-for="index in 5"
                v-bind:class="{ filled: overall >= index }"
                :key="`overall.${index}`"
                @click="setRating('overall', index)"
            )

        p.mb-3
            strong How would you rate the questions?
            br
            i.fa.fa-star(
                v-for="index in 5"
                v-bind:class="{ filled: questions >= index }"
                :key="`questions.${index}`"
                @click="setRating('questions', index)"
            )

        p.mb-3(v-if="tenant.id == 1")
            strong How would you rate the bars?
            br
            i.fa.fa-star(
                v-for="index in 5"
                v-bind:class="{ filled: bars >= index }"
                :key="`bars.${index}`"
                @click="setRating('bars', index)"
            )

        p.mb-3
            strong How likely is it that you would recommend {{ tenant.name }} to a friend?
            br
            i.fa.fa-star(
                v-for="index in 5"
                v-bind:class="{ filled: recommend >= index }"
                :key="`recommend.${index}`"
                @click="setRating('recommend', index)"
            )

        p.mb-3
            strong Do you have any other comments, questions, or concerns? (optional)
            br
            textarea.form-control( v-model="comments" )
        
        button.btn.btn-submit.btn-success.w-100(
            @click="submitFeedback"
            ref="submitFeedback"
            :disabled="!submitEnabled"
        ) Continue
</template>

<script>
// Packages
import swal from 'sweetalert2'

// Services
import { leaveFeedback } from '../services'

export default {
    props: {
        team: { type: Object, required: true },
        user: { type: Object, required: true },
        tour: { type: Object, required: true },
        status: { type: Object, required: true },
        finishTour: { type: Function, required: true },
        tenant: { type: Object, required: true }
    },

    data() {
        return {
            overall: 0,
            questions: 0,
            bars: 0,
            recommend: 0,
            comments: null
        }
    },

    methods: {
        setRating( type, rating ) {
            this[type] = rating
        },

        submitFeedback() {
            // Submit feedback to server, end tour
            leaveFeedback({
                overall: this.overall,
                questions: this.questions,
                bars: this.bars,
                recommend: this.recommend,
                comments: this.comments,
                tour_id: this.tour.id,
                user_id: this.user.id,
                team_id: this.team.id,
                tour_version_id: this.status.tour_version_id
            })
            .then( resp => {
                // Send to leaderboard
                window.location.href = '/'
            })
            .catch( err => {
                console.log( err )
                swal({
                    title: 'An error has occured!',
                    text: 'Please try again.',
                    type: 'error',
                    timer: 2500
                })
            })
        }
    },

    computed: {
        submitEnabled() {
            return this.overall && this.questions && this.recommend
        }
    }
}
</script>

<style lang="scss" scoped>

    i.fa.fa-star {
        font-size: 30px;
        color: #cfcfcf;
        margin: 10px;
    }

    i.fa.fa-star.filled {
        color: #F4CB00;
    }

</style>


