<template lang="pug">
  
    #photo-controls.row
        .col-12.py-2.bg-black( v-show="screen.img && !uploading" )
            .row
                .col-6.text-center
                    a.text-white(
                        @click="triggerFileUpload"
                    ): i.far.fa-edit
                .col-6.text-center
                    a.text-white(
                        @click="removePhoto"
                    ): i.fas.fa-times

        
        .col-12.py-2.bg-black( v-show="!screen.img && !uploading" )
            .row
                .col-12.text-center
                    a.text-white(
                        @click="triggerFileUpload"
                    ): i.fas.fa-plus
        
        .col-12.py-2.bg-black( v-show="uploading" )
            .row
                .col-12.text-center
                    a.text-white: i.fas.fa-spinner.fa-spin
        
        input.d-none(
            type="file"
            ref="image"
            accept="image/*"
            @change="uploadToServer"
        )


</template>

<script>

export default {

    props: {
        screen: { type: Object, required: true }
    },

    data() {
        return {
            file: null,
            uploading: false
        }
    },

    methods: {
        triggerFileUpload() {
            this.$refs.image.click()
        },

        removePhoto() {
            this.screen.img = null
        },

        uploadToServer( e ) {
            this.uploading = true

            // Upload to server and set new url
            let img = this.$refs.image.files[0]
            let data = new FormData()

            data.append( 'image', img )

            axios.post( '/tour-builder-upload', data )
            .then( resp => {
                this.screen.img = resp.data.path
                this.uploading = false
            })
            .catch( error => {
                console.log(error)
                this.uploading = false
            })
        }
    }
    
}
</script>

<style lang="scss">
    #photo-controls {
        .bg-black {
            background: black;
        }

        a {
            transition: ease 400ms;
            opacity: 0.85;
            font-size: 20px;
        }

        a:hover {
            cursor: pointer;
            opacity: 1;
        }

        i.fa-spin {
            color: white;
        }
    }
</style>