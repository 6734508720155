export default {
    methods: {

        /**
         * Captain option selected, broadcast to channel.
         *
         * @param Object captain (user)
         * @return void
         */
        captainOptionSelected( captain ) {
            console.log( 'captain option selected: ', captain)
            this.teamChannel.whisper( 'CaptainSelectionChanged', captain )
        },

        /**
         * Question option selected, broadcast to channel.
         *
         * @param Object option
         * @return void
         */
        optionSelected( option ) {
            console.log( 'option selected: ', option)
            this.teamChannel.whisper( 'OptionSelectionChanged', option )
        }
    }
}