/**
 * Save changes to tour version.
 *
 * @param Object data
 * @return Promise
 */
export function saveChanges( data ) {
    return axios.post( `/tour-builder-save-changes`, data )
}

/**
 * Save as new tour version.
 *
 * @param Object data
 * @return Promise
 */
export function saveAs( data ) {
    return axios.post( `/tour-builder-save-as`, data )
}

/**
 * Save as new tour version.
 *
 * @param Object data
 * @return Promise
 */
 export function saveStatus( data ) {
    return axios.post( `/tour-builder-save-status`, data )
}

/**
 * Refresh tour screens
 *
 * @param Object data
 * @return Promise
 */
export function refreshTourScreens( id ) {
    return axios.get( `/tour-builder-get-screens/${id}` )
}
