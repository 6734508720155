<template lang="pug">

    #screen-window.col-10.bg-white: .row

        .col-12.bg-primary.py-2( v-bind:style="{ backgroundColor: `${tenant.primary_color} !important` }" ): img.logo( :src="tenant.logo" )

        #content.col-12.pt-3.mb-4.bg-white
            h1( v-show="screen.headline" ) {{ screen.headline }}
            h1( v-show="!screen.headline && !screen.bonus" ) Question \#{{ currentQuestionNumber }}
            h1( v-show="!screen.headline && screen.bonus" ) Bonus Question
            
            img.img-fluid.mt-2.mb-3(
                v-if="screen.img"
                :src="screen.img"
            )
            
            p( v-html="screen.content" )
        
            .option( 
                v-if="screen.type !== 'information' && screen.type !== 'text'"
                v-for="option in screen.options"
                v-bind:class="{ active: option.is_correct }"
             ) {{ option.text }}

            #write-in.row(
                :key="refreshKey"
                v-if="screen.type === 'text'"
            ): .text 
                .col-12: .row( v-for="(grouping, groupingIndex) in characters()" style="margin: 0px -5px 5px;" )
                    .col.text-center( v-for="(character, index) in grouping" :key="`${character}-${index}`" )
                        input.character.form-control.mb-3(
                            :ref="`${groupingIndex}-${index}`"
                            type="text"
                            maxlength="1"
                            readonly
                            :value="character"
                            v-bind:class="{ 'non-alphanumeric': isNonAlphaNumeric( character ) }"
                        )
        
        #button.col-12.pb-4.mb-2
            button.btn.btn-submit.btn-success.w-100 {{ screen.button_text || 'Next' }}


</template>

<script>
export default {
    props: {
        screen: { type: Object, required: true },
        currentQuestionNumber: { type: Number, required: false },
        refreshKey: { required: false },
        tenant: { type: Object, required: true }
    },

    methods: {
        isNonAlphaNumeric( character ) {
            return !character.match( /^[0-9a-zA-Z]+$/ )
        },
        
        characters() {
            return this.screen.options.length ? this.screen.options[0].text.split( ' ' ).map( grouping => grouping.split( '' ) ) : ['Cheers!']
        }
    }
}
</script>

<style lang="scss">

    #screen-window {
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.8);

        img.logo {
            max-height: 50px;
        }

        .option {
            width: 100%;
            border: 2px solid #333;
            font-size: 22px;
            margin-bottom: 10px;
            line-height: 22px;
            padding: 15px 10px;
            font-family: bebas-neue, sans-serif;
        }

        .option.active {
            background: #FEDC01;
            border-color: #FEDC01;
        }

        #content {
            padding-left: 32px;
            padding-right: 32px;
        }

        #write-in {
            min-height: 150px;
            justify-content: center;

            input {
                border-radius: 2px;
                max-width: 25px;
            }

            .row {
                justify-content: center;
            }
        }

        .text {
            display: flex;
        }
    }

</style>