<template lang="pug">
    .col-md-8.text-center.tour-content

        h1( v-show="screen.headline" ) {{ screen.headline }}
        h1( v-show="!screen.headline && !screen.bonus" ) Question {{ currentQuestionNumber }}
        h1( v-show="!screen.headline && screen.bonus" ) Bonus Question


        .row.justify-content-center

            .col-md-8.col-11.mb-4( v-if="screen.img" ): progressive-img.img-fluid(
                :src="screen.img"
            )

            .col-md-8.col-11
                p( v-html="screen.content" )
        
        
        .row.form-group.justify-content-center.mt-2
            
            .col-md-8.col-11.mb-4
                b-form-checkbox-group.w-100(
                    buttons
                    stacked
                    size="lg"
                    v-model="selectedOptions"
                    name="selected-option"
                    button-variant="default"
                    class="btn-multi"
                ): b-form-checkbox(
                    v-for="option in screen.options"
                    @change="multiChange"
                    :key="option.id"
                    :value="option"
                    :disabled="!isCaptain"
                ) {{ option.text }}
    
            .col-md-8.col-11( v-if="isCaptain" )
                button.btn.btn-submit.btn-success.w-100(
                    @click="answerQuestion()"
                    ref="answerButton"
                    :disabled="!selectedOptions.length"
                ) {{ screen.button_text }}


</template>

<script>
// Packages
import swal from 'sweetalert2'

// Services
import { answerQuestion } from '../services'

export default {
    props: {
        screen: { type: Object, required: true },
        isCaptain: { type: Boolean, required: true },
        response: { required: true },
        currentQuestionNumber: { type: Number, required: true },
        optionSelected: { type: Function, required: true }
    },

    data() {
        return {
            selectedOptions: [],
        }
    },

    watch: {
        response: {
            handler( newValue ) {
                if( !this.isCaptain ) this.selectedOptions = newValue
            },
            deep: true
        }
    },

    methods: {
        // Workaround for previous value being reported
        multiChange() {
            this.$nextTick( () => {
                this.optionSelected( this.selectedOptions )
            })
        },

        // Send answer to server
        answerQuestion() {
            
            let response = this.selectedOptions

            // Set button to disabled to prevent accidental click-aheads
            this.buttonDisabled = true

            answerQuestion( response, this.screen.id )
            .then( res => {console.log('answer submitted successfully', res)} )
            .catch( err => {
                swal({
                    title: 'An error has occured!',
                    text: 'Please try again.',
                    type: 'error',
                    timer: 2500
                })
            })
            .finally( () => {
                setTimeout( () => this.buttonDisabled = false, 1000 )
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

