<template lang="pug">

    #tour.container: .row.justify-content-center

        select-captain(
            v-if="status.current_stage === 'SELECT_CAPTAIN'"
            :status="status"
            :team="team"
            :is-captain="isCaptain"
            :captain-option-selected="captainOptionSelected"
        )

        how-it-works(
            v-if="status.current_stage === 'HOW_IT_WORKS'"
            :status="status"
            :team="team"
            :is-captain="isCaptain"
            :tenant="tenant"
        )

        information-screen(
            v-if="currentScreen.type === 'information'"
            :screen="currentScreen"
            :is-captain="isCaptain"
        )

        text-input-screen(
            v-if="currentScreen.type === 'text'"
            :key="`text-${status.current_screen}`"
            :screen="currentScreen"
            :is-captain="isCaptain"
            :response="response"
            :current-question-number="currentQuestionNumber"
            :option-selected="optionSelected"
        )

        m-c-single-screen(
            v-if="currentScreen.type === 'mc_single'"
            :key="`single-${status.current_screen}`"
            :screen="currentScreen"
            :is-captain="isCaptain"
            :response="response"
            :current-question-number="currentQuestionNumber"
            :option-selected="optionSelected"
        )

        m-c-multiple-screen(
            v-if="currentScreen.type === 'mc_multiple'"
            :key="`multi-${status.current_screen}`"
            :screen="currentScreen"
            :is-captain="isCaptain"
            :response="response"
            :current-question-number="currentQuestionNumber"
            :option-selected="optionSelected"
        )

        tour-end(
            v-if="status.current_stage === 'FINISHED'"
            :status="status"
            :tour="tour"
            :team="team"
            :tenant="tenant"
            :trigger-feedback="triggerFeedback"
        )

        feedback(
            v-if="status.current_stage === 'FEEDBACK'"
            :tour="tour"
            :team="team"
            :user="user"
            :status="status"
            :tenant="tenant"
        )

        sidebar(
            v-if="status.current_stage === 'QUESTIONS'"
            :status="status"
            :tour="tour"
            :screens="screens"
            :team="team"
            :user="user"
            :is-captain="isCaptain"
            :tenant="tenant"
        )

        change-captain(
            :is-captain="isCaptain"
            :team="team"
            :user="user"
        )

        add-players(
            :team="team"
        )

</template>


<script>
// Packages
import swal from 'sweetalert2'

// Tour channel events/handlers
import events from './mixins/events'
import listeners from './mixins/listeners'
import misc from './mixins/misc'

// Components
import SelectCaptain from './components/SelectCaptain'
import HowItWorks from './components/HowItWorks'
import InformationScreen from './components/InformationScreen'
import TextInputScreen from './components/TextInputScreen'
import MCSingleScreen from './components/MCSingleScreen'
import MCMultipleScreen from './components/MCMultipleScreen'
import Feedback from './components/Feedback'
import TourEnd from './components/TourEnd'
import Sidebar from './components/Sidebar'
import ChangeCaptain from './components/ChangeCaptain'
import AddPlayers from './components/AddPlayers'

export default {
    props: {
        initUser: { type: Object, required: true },
        initTeam: { type: Object, required: true },
        initTour: { type: Object, required: true },
        initIsCaptain: { type: Boolean, required: true },
        tenant: { type: Object, required: true }
    },

    mixins: [
        events, listeners, misc
    ],

    components: {
        SelectCaptain, HowItWorks, ChangeCaptain, AddPlayers,
        InformationScreen, TextInputScreen, MCSingleScreen,
        MCMultipleScreen, TourEnd, Feedback, Sidebar
    },

    data() {
        return {

            // Current user
            user: this.initUser,

            // Current team
            team: this.initTeam,

            // Current tour
            tour: this.initTour,

            // Captain flag (only captains can commit actions)
            isCaptain: this.initIsCaptain,
            
            // Tour Status for team
            status: this.initTeam.status,

            // Tour Screens
            screens: this.initTeam.status.tour_version.screens,

            // Current response to question + isCorrect
            response: {},

            // Team's collection of responses
            responses: this.initTeam.responses,

            // Team Channel (https://laravel.com/docs/7.x/broadcasting)
            teamChannel: this.$echo.join( `App.Team.${this.initTeam.id}` ),
            
            // Users currently in channel
            presentUsers: []
        }
    },

    methods: {
        browserVisibilityChangeEvent() {
            // Browser-specific event name
            let visibilityChange

            if ( typeof document.hidden !== "undefined" ) { // Opera 12.10 and Firefox 18 and later support 
                visibilityChange = "visibilitychange"
            } else if ( typeof document.msHidden !== "undefined" ) {
                visibilityChange = "msvisibilitychange"
            } else if ( typeof document.webkitHidden !== "undefined" ) {
                visibilityChange = "webkitvisibilitychange"
            }

            return visibilityChange
        },

        browserVisibilityHidden() {
            // Browser-specific 'hidden' property name
            let hidden

            if ( typeof document.hidden !== "undefined" ) { // Opera 12.10 and Firefox 18 and later support 
                hidden = "hidden"
            } else if ( typeof document.msHidden !== "undefined" ) {
                hidden = "mshidden"
            } else if ( typeof document.webkitHidden !== "undefined" ) {
                hidden = "webkitHidden"
            }

            return hidden
        },

        triggerFeedback() {
            this.status.current_stage = 'FEEDBACK'
        }
    },

    mounted() {
        console.log('initializing with data:', this.user, this.status, this.team, this.tour, this.isCaptain)

        // Listen to team channel, listen for events (definitions in ./mixins/listeners.js)
        this.teamChannel

            // Channel-based event listeners
            .here( this.initializePresentUsers )
            .joining( this.userJoinedChannel )
            .leaving( this.userLeftChannel )
            
            // Server-based event Listeners
            .listen( 'UserJoinedTour', this.userJoinedTour )
            .listen( 'CaptainSelected', this.captainSelected )
            .listen( 'StatusUpdated', this.statusUpdated )
            .listen( 'QuestionAnswered', this.questionAnswered )
            .listen( 'PlayersAddedToTeam', this.playersAddedToTeam )

            // Client-based event listeners
            .listenForWhisper( 'CaptainSelectionChanged', this.captainSelectionChanged )
            .listenForWhisper( 'OptionSelectionChanged', this.optionSelectionChanged )

        // Detect browser visibility change (https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API)
        document.addEventListener( this.browserVisibilityChangeEvent(), this.browserVisibilityChanged )
    }
}
</script>

<style lang="scss">

.btn.disabled, .btn:disabled {
    opacity: .8;
}

.swal2-toast {
    .swal2-actions{
        button.swal2-confirm{
            font-size: 12px;
        }
    }
}

</style>

