<template lang="pug">
  
    #screen-index.col-2.text-center.py-3
        p Pages
        
        draggable(
            v-if="allowReordering"
            v-model="screens"
            @end="regenerateOrdinalsOnReorder"
        )
            .row.mb-4( 
                v-for="(screen, index) in screens"
                :key="screen.ordinal"
                @contextmenu.prevent="$refs.menu.open($event, { screen: screen })"
                @click="selectScreen( screen )"
                v-bind:class="{ selected: screen.ordinal === selectedOrdinal }"
            )
                .col-1 {{ index + 1 }}
                .col: .row.justify-content-center
                    screen-preview(
                        :screen="screen"
                        :tenant="tenant"
                    )
        
        .row.mb-5(
            v-if="!allowReordering"
            v-for="(screen, index) in screens"
            :key="screen.ordinal"
            @click="selectScreen( screen )"
            v-bind:class="{ selected: screen.ordinal === selectedOrdinal }"
        )
            .col-1 {{ index + 1 }}
            .col: .row.justify-content-center
                screen-preview(
                    :screen="screen"
                    :tenant="tenant"
                )
        
        vue-context(
            ref="menu"
            @open="openContextMenu"
        )
            li: a( @click="deleteScreen" ) Delete Screen
                        
        #add-page.div.w-100.text-center( v-if="allowReordering" )
            p.m-0(
                @click="addScreen"
            )
                i.fas.fa-plus-circle
                | &nbsp;Add

</template>

<script>
import draggable from 'vuedraggable'
import { VueContext } from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'

import ScreenPreview from './ScreenPreview'

export default {

    components: {
        draggable, ScreenPreview, VueContext
    },
    
    props: {
        selectedOrdinal: { type: Number, required: true },
        initScreens: { type: Array, required: true },
        allowReordering: { type: Boolean, required: true },
        tenant: { type: Object, required: true }
    },

    data() {
        return {
            screens: this.initScreens,
            screenSelectedToDelete: null
        }
    },

    methods: {
        selectScreen( screen ) {
            this.$emit( 'screen-selected', screen.ordinal )
        },

        addScreen() {
            this.$emit( 'add-screen' )
        },

        deleteScreen( event, screen = this.screenSelectedToDelete ) {
            this.$emit( 'delete-screen', screen )
        },

        openContextMenu( event, { screen } ) {
            this.screenSelectedToDelete = screen
        },

        regenerateOrdinalsOnReorder( event ) {
            let newSelectedOrdinal = event.newIndex + 1
            this.screens.forEach( ( screen, index ) => {
                screen.ordinal = index + 1
            })
            this.$emit( 'screen-reordered', this.screens, newSelectedOrdinal )
        }
    }
    
}
</script>

<style lang="scss">

    #screen-index {
        z-index: auto;
        background: #f5f5f5;
        height: calc( 100vh - 72px );
        max-width: 180px;
        box-shadow: 0px 0px 26px -8px rgba(0, 0, 0, 0.8);
        overflow-y: auto;

        p {
            font-family: ibm-plex-mono,sans-serif;
        }

        #screen-window {
            //zoom: 7%;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.9);
            user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;

            // .py-2 {
            //     padding-top: 1rem !important;
            //     padding-bottom: 1rem !important;
            // }

            .bg-primary.py-2 {
                padding-top: 2px !important;
                padding-bottom: 2px !important;
            }

            h1 {
                font-size: 12px;
                margin-top: 0px;
                margin-bottom: 0px;
            }

            p {
                font-family: "Helvetica Neue", sans-serif;
                font-size: 7px;
                line-height: 8px;
                transform: scale(0.95);
            }

            img {
                transform: scale(0.95);
                margin-top: 0px !important;
                margin-bottom: 0px !important;
            }

            img.logo {
                max-width: 100%;
                height: 17px;
                width: auto;
            }

            .option {
                margin-bottom: 5px;
                padding: 2px 0px;
                font-size: 7px;
                line-height: 9px 
            }

            #content {
                margin-bottom: 5px !important;
                padding-left: 3px;
                padding-right: 3px;
                padding-top: 5px !important;
            }

            #button {
                padding-left: 3px !important;
                padding-right: 3px !important;
                padding-bottom: 0px !important;
                margin-bottom: 5px !important;
                
                button {
                    font-size: 8px;
                    min-height: 20px;
                    border-radius: 8px;
                    margin-bottom: 3px !important;
                    padding: 3px 0;
                }
            }

            #write-in {
                min-height: 30px;
                padding-right: 10px;
                padding-left: 10px;

                .col {
                    padding-left: 2px;
                    padding-right: 2px;
                }
                
                input {
                    font-size: 8px;
                    min-height: 15px;
                    border-radius: 8px;
                    margin-bottom: 3px !important;
                }
            }
        }

        .selected {
            #screen-window {
                box-shadow: 0px 0px 5px 0px #000000;
            }
            .col-1 {
                font-weight: 700;
            }
        }

        #screen-window:hover {
            cursor: pointer;
        }
    }

    #add-page {
        font-size: 14px;

        p {
            transition: 300ms;
            opacity: 0.85;
        }

        p:hover {
            cursor: pointer;
            opacity: 1;
        }
    }

</style>