export default () => {
    let $nav = $( '#admin-nav' )
    let $content = $( '#admin-content' )

    $nav.addClass( 'transition' )
    $content.addClass( 'transition' )

    $( '#menu-toggle' ).click( () => {
        $nav.toggleClass( 'collapsed' )
        $content.toggleClass( 'collapsed' )
    })
}