// Packages
import swal from 'sweetalert2'

// Services
import { selectCaptain } from '../services'

export default {
    methods: {

        /**
         * Initialize users present on channel.
         *
         * @param Array users
         * @return void
         */
        initializePresentUsers( users ) {
            console.log( 'users present', users )
            this.presentUsers = users
        },

        /**
         * Players added to team.
         *
         * @param Object team
         * @return void
         */
        playersAddedToTeam( {team} ) {
            console.log( 'players added to team', team )
            this.team = team
        },

        /**
         * Handle user joining channel.
         *
         * @param Object user
         * @return void
         */
        userJoinedChannel( user ) {
            console.info( 'user joined', user )
            this.presentUsers.push( user )

            // Captain reconnected
            if( this.team.captain_id === user.id ) {
                swal.fire({
                    toast: true,
                    position: 'bottom',
                    customClass: 'notification-toast',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    },
                    showConfirmButton: false,
                    type: 'success',
                    title: 'Captain reconnected!',
                    timer: 2000
                })
            }
            
            // Set pending invite to false
            let userData = this.team.users.find( u => u.id === user.id )
            userData.pending_invitation = false;
            console.log( 'users present now: ', this.team.users )
        },

        /**
         * Handle user leaving channel.
         *
         * @param Object user
         * @return void
         */
        userLeftChannel( user ) {
            console.info( 'user left', user )
            this.presentUsers = this.presentUsers.filter( presentUser => presentUser.id !== user.id )

            // Captain disconnected
            if( this.team.captain_id === user.id ) {
                swal.fire({
                    toast: true,
                    position: 'bottom',
                    customClass: 'notification-toast',
                    showConfirmButton: true,
                    type: 'warning',
                    title: 'Captain disconnected.',
                    confirmButtonText: 'Autoselect New Captain',
                    onAfterClose: ( el ) => {
                        console.log( 'auto-selecting new captain...' )
                        
                        // Pluck user from users in channel
                        let newCaptain = this.presentUsers[0]

                        // Send captain selection to server
                        selectCaptain( newCaptain )
                        .then( res => console.log('new captain auto-selected', res) )
                        .catch( err => {
                            swal.fire({
                                title: 'An error has occured!',
                                text: 'Please try again.',
                                type: 'error',
                                timer: 2500
                            })
                        })
                    }
                })
            }

            // Set pending invite to true
            let userData = this.team.users.find( u => u.id === user.id )
            userData.pending_invitation = true;
            console.log( 'users present now: ', this.team.users )
        },

        /**
         * Handle user joining tour, alert members.
         *
         * @param Object user
         * @return void
         */
        userJoinedTour( {user} ) {
            console.log( 'user joined tour: ', user )
            // Alert group
            swal.fire({
                toast: true,
                position: 'bottom-start',
                customClass: {
                    container: 'notification-toast'
                },
                showClass: {
                    popup: 'animate__animated animate__faster animate__fadeIn'
                },
                hideClass: {
                    popup: 'animate__animated animate__faster animate__fadeOut'
                },
                showConfirmButton: false,
                type: 'success',
                html: `
                    <strong>${user.email}</strong> joined your team
                `,
                timer: 5000
            })
        }, 

        /**
         * Handle captain selection changed event handler.
         *
         * @param Object captain (user)
         * @return void
         */
        captainSelectionChanged( captain ) {
            console.log( 'captain selection changed: ', captain )
            // Handle
            this.team.captain_id = captain.id
        },

        /**
         * Handle option selection changed event.
         *
         * @param Object option
         * @return void
         */
        optionSelectionChanged( option ) {
            console.log( 'option selection changed: ', option )
            // Handle
            this.response = option
        },

        /**
         * Handle captain selected event.
         *
         * @param Object {team}
         * @return void
         */
        captainSelected( {team} ) {
            console.log( 'captain selected, updated team: ', team)
            // Handle
            this.team = team

            // Update captain permissions
            this.isCaptain = this.user.id === this.team.captain_id
        },

        /**
         * Handle status updated event.
         *
         * @param Object {status}
         * @return void
         */
        statusUpdated( {status} ) {
            console.log( 'status updated: ', status )
            
            // Scroll to top of page
            window.scrollTo( 0,0 )
            //positionControls()
            
            // Handle
            this.status = status
        },

        /**
         * Question answered event handler.
         *
         * @param Object {responses, status, isCorrect}
         * @return void
         */
        questionAnswered( {responses, status, isCorrect, correctAnswer, pointsEarned} ) {
            console.log( 'question answered, status updated: ', status, responses )
            
            // Scroll to top of page
            window.scrollTo( 0, 0 )
            //positionControls()

            // Launch answer feedback modal
            swal.fire({
                customClass: 'answer-feedback-modal',
                showTitle: false,
                showClass: {
                    popup: 'animate__animated animate__slideInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__slideOutDown animate__fast'
                },
                html: `
                    <h2 class="swal2-title" id="swal2-title" style="display: flex;justify-content: center;">${isCorrect ? "That's Right!" : "Sorry... That Wasn't It"}</h2>
                    <img class="swal2-image" src="${isCorrect ? '/correct' : '/incorrect'}" alt="">
                    <div class="modal-container">
                        <div class="correct-response">
                            <dd>The Correct Response was</dd>
                            <dt>${correctAnswer}</dt>
                        </div>
                        <div class="current-status">
                            <div class="column">
                                <dd>You earned</dd>
                                <dt>${pointsEarned}<span>pts</span></dt>
                            </div>
                            <div class="column">
                                <dd>Total Score</dd>
                                <dt>${status.score}<span>pts</span></dt>
                            </div>
                        </div>
                    </div>
                `,
                confirmButtonText: 'Continue'
            })
            
            // Handle
            this.status = status
            this.responses = [...this.responses, ...responses]
        },

         /**
         * Browser visiblility changed event handler.
         * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
         *
         * @param Event e
         * @return void
         */
        browserVisibilityChanged( e ) {
            console.log( 'browser visibility changed', document[this.browserVisibilityHidden()] )
            
            // Check visibility (browser-specific names)
            let isHidden = document[this.browserVisibilityHidden()]
            
            // If "re-appearing", meaning visible again- reload
            if( !isHidden ) window.location.reload()
        },
    }
}