<template lang="pug">
  
    .row

        .col-6
            .btn-header.w-100.py-2.mb-3.text-center(
                v-bind:class="{ active: currentFocus === 'buyer' }"
            )
                label Buyer email

            .form-group
                label Tour Location & Description:
                input.form-control(
                    type="text"
                    v-model="tourVersion.buyer_email_header"
                    @focus="editorFocused('buyer')"
                    @change="editorChange"
                )
            
            .form-group
                label Content:
                vue-editor(
                    id="buyer-editor"
                    v-model="tourVersion.buyer_email_text"
                    :editor-toolbar="toolbar"
                    @focus="editorFocused('buyer')"
                    @text-change="editorChange"
                )
        
        .col-6
            .btn-header.w-100.py-2.mb-3.text-center(
                v-bind:class="{ active: currentFocus === 'teammate' }"
            )
                label Teammate email

            .form-group
                label Tour Location & Description:
                input.form-control(
                    type="text"
                    v-model="tourVersion.teammate_email_header"
                    @focus="editorFocused('teammate')"
                    @change="editorChange"
                )
            
            .form-group
                label Content:
                vue-editor(
                    id="teammate-editor"
                    v-model="tourVersion.teammate_email_text"
                    :editor-toolbar="toolbar"
                    @focus="editorFocused('teammate')"
                    @text-change="editorChange"
                )
            


</template>

<script>
import moment from 'moment'
import { VueEditor } from "vue2-editor"

export default {

    props: {
        tourVersion: { type: Object, required: true },
        currentFocus: { type: String, required: true }
    },

    data() {
        return {
            toolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]],
        }
    },

    methods: {
        editorFocused( emailType ) {
            this.$emit( 'email-focused', emailType )
        },

        editorChange() {
            //this.$emit( 'email-updated', moment().unix() )
        }
    }
    
}
</script>

<style lang="scss">
    .btn-header {
        border: 2px solid black;
    }

    .btn-header.active {
        color: white;
        background: black;
    }
</style>