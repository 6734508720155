<template lang="pug">
    .col-md-8.text-center.tour-content
    
        h1 How It Works

        .row.justify-content-center

            .col-md-8.col-11
                p 
                    | Let's get this party started!
                p
                    | Each numbered question on the tour is worth 15 points and all bonus questions are worth 5 points.
                p( v-if="tenant.id === 1" )
                    | To answer some questions you may be required to 
                    | enter a bar or explore the space around you. Other questions rely on your team's trivia knowledge.
                p( v-if="tenant.id !== 1" )
                    | To answer some questions you may be required to 
                    | explore the space around you. Other questions rely on your team's trivia knowledge.
                p
                    | You can use your phone for some <em>additional research</em> if necessary—that's not considered cheating.
        
        .row.form-group.justify-content-center.mt-4
            
            .col-md-8.col-11( v-if="isCaptain" )
                button.btn.btn-submit.btn-success.w-100( @click="continueTour()" ) Okay, Let's Go!


</template>

<script>
// Packages
import swal from 'sweetalert2'

// Services
import { beginQuestions } from '../services'

export default {
    props: {
        team: { type: Object, required: true},
        status: { type: Object, required: true },
        isCaptain: { type: Boolean, required: true },
        tenant: { type: Object, required: true }
    },

    methods: {
        continueTour() {
            // TO DO (eventually): Refactor template to somewhere...cleaner
            swal.fire({
                html: `
                    <h1>If you get logged out...</h1>
                    <p>
                        You can return to the tour by clicking the "Get Started" link in your Tour Activation email or using the QR code from your teammates device.
                    </p>
                `,
                confirmButtonText: 'Got it. Let\'s do this!'
            })
            .then( (result) => {
                if( result ) {
                    // Notify server
                    beginQuestions()
                    .then( res => {console.log('successful request to begin questions', res)} )
                    .catch( err => {
                        swal({
                            title: 'An error has occured!',
                            text: 'Please try again.',
                            type: 'error',
                            timer: 2500
                        })
                    })
                }
            })
        }
    },
}
</script>

<style lang="scss">
    
    .swal2-popup button.swal2-confirm.swal2-styled {
        background-color: #FEDC01;
        border-color: #FEDC01;
        color: black;
    }

</style>

