<template lang="pug">

    b-modal#add-players(
        ref="addPlayers"
        title="Add Players"
        :centered="true"
        :hide-footer="true"
    )

        div.justify-content-center.text-center
            
            p You purchased a {{ seats }} person tour.
            p( v-show="seats !== 'UNLIMITED'" ): strong You have {{ seatsRemaining }} spot(s) left.
            div.w-100.mb-3( v-html="qrCode" )
        

        form( v-show="seatsRemaining > 0 || seats === 'UNLIMITED'" name="add-players" )
            b-form-input.mb-3(
                v-model="users[0]"
                type="email"
                placeholder="your@email.com"
            )


        div.justify-content-center.text-center.mt-5.mb-2
            b-btn.btn-success.w-100(
                size="lg"
                @click.prevent="addPlayers"
                :disabled="!seatsRemaining"
            ) Continue

</template>

<script>
// Packages
import swal from 'sweetalert2'

// Services
import { addPlayers } from '../services'

export default {
    props: {
        team: { type: Object, required: true }
    },

    data() {
        return {
            users: [],
            qrCode: null
        }
    },

    computed: {
        seats() {
            switch( this.team.seats ) {
                case null: return 'UNLIMITED'
                default: return this.team.seats
            }
        },

        seatsRemaining() {
            return this.team.seats ? this.team.seats - this.team.users.length : 'UNLIMITED'
        }
    },

    methods: {
        closeModal() {
            this.$refs.addPlayers.hide()
        },

        addPlayers() {
            this.$refs.addPlayers.hide()

            // Send to server
            addPlayers( this.users )
            .then( res => {
                console.log( 'players added', res )
                this.users = []
            })
            .catch( err => {
                swal.fire({
                    title: 'An error has occured!',
                    text: 'Please try again.',
                    type: 'error',
                    timer: 2500
                })
            })
        }
    },

    created() {
        axios.get('/tour/qr/170')
        .then(({data}) => {
            this.qrCode = data.qr_code
        })
        .catch(err => console.log(err))
    }
}
</script>

<style lang="scss">

    #add-players {
        .modal-header, .modal-body {
            display: block;
        }

        button.close {
            display: none;
        }

        h5.modal-title {
            font-size: 40px;
            text-align: center;
        }

        .btn.w-100 {
            font-weight: 600;
        }
    }

</style>

