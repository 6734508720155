/**
 * Update captain on backend.
 *
 * @param Object captain (user)
 * @param String teamname, default null
 * @return Promise
 */
export function selectCaptain( captain, teamname = null ) {
    return axios.post( `/tour/select-captain`, { captain, teamname } )
}

/**
 * Set status to QUESTIONS on backend.
 *
 * @return Promise
 */
export function beginQuestions() {
    return axios.get( `/tour/begin-questions` )
}

/**
 * Advance to next screen (either a new question, or explanation stage of correct answer).
 *
 * @return Promise
 */
export function nextScreen() {
    return axios.get( `/tour/next-question` )
}

/**
 * Submit response to question.
 *
 * @return Promise
 */
export function answerQuestion( response, screen_id ) {
    return axios.post( `/tour/answer-question/${screen_id}`, {response} )
}

/**
 * Add teammates to tour.
 *
 * @return Promise
 */
export function addPlayers( teammates ) {
    return axios.post( `/register-team-ajax`, {teammates} )
}

/**
 * Leave feedback on tour.
 *
 * @return Promise
 */
export function leaveFeedback( feedback ) {
    return axios.post( `/leave-feedback`, feedback )
}
