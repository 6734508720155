<template lang="pug">
    .container-fluid: .row
        
        //- Screen Drag'n'Drop (many little screen previews)
        screen-index(
            v-if="currentEditor === 'screen'"
            :selected-ordinal="selectedOrdinal"
            :init-screens="screens"
            :allow-reordering="!isActive"
            :tenant="tenant"
            @screen-selected="screenSelected"
            @screen-reordered="screenReordered"
            @add-screen="addNewScreen"
            @delete-screen="deleteScreen"
        )

        .col: .row
            
            //- Builder save buttons + view toggles
            builder-menu(
                :tour-version="tourVersion"
                :current-editor="currentEditor"
                :is-active="isActive"
                @editor-changed="editorChanged"
                @save-changes="saveVersionChanges"
                @save-as="saveAsNewVersion"
            )

            //- Preview panel (screen, email, leaderboard (editor), referral)
            #screen-preview.col.text-center.py-3.px-4: .row.justify-content-center
                screen-preview(
                    v-if="currentEditor === 'screen'"
                    :refresh-key="screenPreviewKey"
                    :screen="selectedScreen"
                    :options="selectedScreen.options"
                    :current-question-number="currentQuestionNumber"
                    :tenant="tenant"
                )

                email-preview(
                    v-if="currentEditor === 'email'"
                    :refresh-key="emailPreviewKey"
                    :headline="emailHeadline"
                    :content="emailContent"
                    :tenant="tenant"
                )

                leaderboard-editor(
                    v-if="currentEditor === 'referral'"
                    :refresh-key="referralPreviewKey"
                    :leaderboard="leaderboard"
                    :tour-version="tourVersion"
                    :tenant="tenant"
                    :show-popup="currentReferralEditor === 'referral'"
                )


            //- Editors (screen, email, referral) 
            #editor.col.p-4
                screen-editor(
                    v-if="currentEditor === 'screen'"
                    :screen="selectedScreen"
                    :is-active="isActive"
                    @screen-updated="screenUpdated"
                    @delete-option="deleteOption"
                )

                email-editor(
                    v-if="currentEditor === 'email'"
                    :tour-version="tourVersion"
                    :current-focus="currentEmail"
                    @email-focused="emailFocused"
                    @email-updated="emailUpdated"
                )

                referral-editor(
                    v-if="currentEditor === 'referral'"
                    :available-tours="availableTours"
                    :tour-version="tourVersion"
                    :current-focus="currentReferralEditor"
                    @referral-focused="referralEditorFocused"
                    @referral-updated="referralEditorUpdated"
                )
    
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert2'

import BuilderMenu from './components/BuilderMenu'
import ScreenIndex from './components/ScreenIndex'
import ScreenPreview from './components/ScreenPreview'
import ScreenEditor from './components/ScreenEditor'
import EmailPreview from './components/EmailPreview'
import EmailEditor from './components/EmailEditor'
import ReferralEditor from './components/ReferralEditor'
import LeaderboardEditor from './components/LeaderboardEditor'

import { saveChanges, saveAs, refreshTourScreens } from './services'

// Remove single key from object
const omitSingle = (key, { [key]: _, ...obj }) => obj

export default {

    props: {
        initTourVersion: { type: Object, required: true },
        initLeaderboard: { type: Array, required: true },
        availableTours: { type: Array, required: true },
        tenant: { type: Object, required: true }
    },

    components: {
        ScreenIndex, BuilderMenu, ScreenPreview,
        ScreenEditor, EmailPreview, EmailEditor,
        ReferralEditor, LeaderboardEditor
    },

    data() {
        return {
            selectedOrdinal: 1,
            screenPreviewKey: moment().unix(),
            emailPreviewKey: moment().unix(),
            referralPreviewKey: moment().unix(),
            tourVersion: omitSingle('screens', this.initTourVersion),
            leaderboard: this.initLeaderboard,
            screens: this.initTourVersion.screens,
            currentEditor: 'screen',
            currentEmail: 'buyer',
            currentReferralEditor: 'leaderboard',
            screensToDelete: [],
            optionsToDelete: []
        }
    },

    computed: {
        selectedScreen() {
            return this.screens.find( screen => screen.ordinal === this.selectedOrdinal )
        },

        currentQuestionNumber() {
            // Filter questions by not bonus + not type 'intro' + not type 'transition'
            return this.screens
                    .filter( screen => {
                        if(
                            screen.bonus ||
                            screen.type === 'information'
                        ) return false

                        return true
                    })
                    .findIndex( screen => screen.ordinal === this.selectedOrdinal ) + 1
        },

        isActive() {
            return this.tourVersion.id === this.tourVersion.tour.active_version_id
        },

        emailHeadline() {
            return this.currentEmail === 'buyer' ? this.tourVersion.buyer_email_header : this.tourVersion.teammate_email_header
        },

        emailContent() {
            return this.currentEmail === 'buyer' ? this.tourVersion.buyer_email_text : this.tourVersion.teammate_email_text
        }
    },

    methods: {
        screenSelected( ordinal ) {
            this.selectedOrdinal = ordinal
        },

        addNewScreen() {
            this.screens.push({
                tour_version_id: this.tourVersion.id,
                type: 'information',
                img: null,
                ordinal: this.screens.length + 1,
                headline: 'New Headline',
                content: 'Enter some content...',
                bonus: 0,
                options: []
            })

            this.selectedOrdinal = this.screens.length
        },

        editorChanged( editor ) {
            this.currentEditor = editor
        },

        screenUpdated() {
            this.screenPreviewKey = moment().unix()
            this.$forceUpdate()
        },

        screenReordered( screens, newSelectedOrdinal ) {
            this.screens = screens
            this.selectedOrdinal = newSelectedOrdinal
        },

        emailFocused( emailType ) {
            this.currentEmail = emailType
        },

        emailUpdated( key ) {
            this.emailPreviewKey = key
        },

        referralEditorFocused( editorType ) {
            this.currentReferralEditor = editorType
        },

        referralEditorUpdated( key ) {
            //this.referralPreviewKey = key
            this.currentReferralEditor = 'leaderboard'
            setTimeout(() => this.currentReferralEditor = 'referral', 100)
        },

        saveVersionChanges() {
            // Loading...
            swal.fire({
                toast: true,
                position: 'bottom-end',
                customClass: 'zoom',
                showConfirmButton: false,
                icon: 'info',
                title: 'Saving Changes...'
            })
            
            // Assemble data
            let data = {
                id: this.tourVersion.id,
                tourVersion: this.tourVersion,
                screens: this.screens,
                screensToDelete: this.screensToDelete,
                optionsToDelete: this.optionsToDelete
            }
            
            saveChanges( data )
            .then( result => {
                swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    customClass: 'zoom',
                    showConfirmButton: false,
                    icon: 'success',
                    title: 'Tour Updated!',
                    timer: 2000
                })
                this.refreshTourScreens()
            })
            .catch( error => {
                console.log(error)
                swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    customClass: 'zoom',
                    showConfirmButton: false,
                    icon: 'error',
                    title: 'An error has occurred.',
                    timer: 2000
                })
            })
        },

        saveAsNewVersion( version ) {
            // Loading...
            swal.fire({
                toast: true,
                position: 'bottom-end',
                customClass: 'zoom',
                showConfirmButton: false,
                icon: 'info',
                title: `Saving as new version ${version}...`
            })
            
            // Assemble data
            let data = {
                version_number: version,
                previous_id: this.tourVersion.id,
                tourVersion: this.tourVersion,
                screens: this.screens,
                screensToDelete: this.screensToDelete,
                optionsToDelete: this.optionsToDelete
            }
            
            saveAs( data )
            .then( result => {
                swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    customClass: 'zoom',
                    showConfirmButton: false,
                    icon: 'success',
                    title: 'Tour Version created!',
                    timer: 2000
                })
                console.log(result.data)
                window.location.href = `/tour-builder/${result.data.tourVersion.id}`
            })
            .catch( error => {
                console.log(error)
                swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    customClass: 'zoom',
                    showConfirmButton: false,
                    icon: 'error',
                    title: 'An error has occurred.',
                    timer: 2000
                })
            })
        },

        refreshTourScreens() {
            // Pull screens
            refreshTourScreens( this.tourVersion.id )
            .then( ({ data }) => {
                console.log(data)
                this.screens = data.screens
            })
            .catch( error => {
                console.log(error)
            })
        },

        deleteScreen( screenToDelete ) {
            if( screenToDelete.id ) {
                this.screensToDelete.push( screenToDelete )
            }
            
            // Before removing, select a previous screen, and if that doesn't exist, select next screen
            let index = this.screens.findIndex( screen => screenToDelete.ordinal === screen.ordinal )

            if( this.selectedOrdinal === screenToDelete.ordinal ) {
                let jumpToScreen = index > 0 ? this.screens[index - 1] : this.screens[1]
                this.screenSelected( jumpToScreen.ordinal )
            } else {
                // Do nothing
            }    
            
            this.screens.splice( index, 1 )

            this.screens.forEach( ( screen, index ) => {
                screen.ordinal = index + 1
            })
        },

        deleteOption( optionToDelete ) {
            this.optionsToDelete.push( optionToDelete )
        }
    }

}
</script>

<style lang="scss">

    .zoom {
        zoom: 150%;
    }

    #screen-preview {
        height: calc(100vh - 125px);
        box-shadow: 0px 0px 26px -8px rgba(0, 0, 0, 0.8);
        z-index: 1;
        max-width: 490px;
        overflow-y: scroll;
        background: #fafafa;

        #screen-window {
            min-height: 768px;
        }
    }


    #editor {
        background: #f5f5f5;
        overflow-y: scroll;

        .ql-editor {
            height: 500px;
            background: white;

            p {
                margin-bottom: 10px;
            }
        }

        .form-control, .input-group {
            border-radius: 4px;
            border: none;

            .input-group-prepend .input-group-text {
                border: 0;
                background: black;
                color: white;
            }

            .input-group-append .input-group-text {
                border: 0;
                background: rgb(153, 153, 153);
                color: black;
            }
        }

        .input-group .form-control {
            font-size: 95%;
            padding-right: 40px;
        }

        .correct-indicator {
            position: absolute;        
            right: 45px;
            top: 13px;
            font-size: 18px;
            z-index: 99;

            .text-success {
                color: green !important;
            }
        }

        .correct-indicator:hover {
            cursor: pointer;
        }

        .page-type-option {
            background: white;
            font-family: 'ibm-plex-mono', sans-serif;
            text-transform: uppercase;
            font-weight: 700;
        }

        .page-type-option:hover, .page-type-option.active {
            background: #FEDC01;
            color: black;
            cursor: pointer;
        }

        label {
            font-weight: 600;
            font-size: 16px;
            font-family: 'ibm-plex-mono', sans-serif;
        }

        .btn-outline-secondary {
            width: 50%;
        }

        .btn-outline-secondary.active {
            background: #F4CB00;
            color: black;
            border-color: #F4CB00;
        }
    }

</style>