<template lang="pug">
  
    .row

        .col-6

            .form-group
                label Headline:
                input.form-control(
                    type="text"
                    v-model="screen.headline"
                )
            
            .form-group
                label Photo:
                .col-12: photo-controls(
                    :screen="screen"
                )
            
            .form-group
                label Content:
                vue-editor(
                    id="content-editor"
                    v-model="screen.content"
                    :editor-toolbar="toolbar"
                    @text-change="contentChanged"
                )
        
        .col-6

            .form-group
                label Page Type:
                .page-type-option.text-center.py-3.mb-2(
                    v-bind:class="{ active: screen.type === 'information', disabled: isActive }"
                    @click="selectScreenType( 'information' )"
                ) Information
                .page-type-option.text-center.py-3.mb-2(
                    v-bind:class="{ active: screen.type === 'mc_single' || screen.type === 'mc_multiple', disabled: isActive }"
                    @click="selectScreenType( 'mc_single' )"
                ) Multiple Choice
                .page-type-option.text-center.py-3.mb-2(
                    v-bind:class="{ active: screen.type === 'text', disabled: isActive }"
                    @click="selectScreenType( 'text' )"
                ) Write In
            
            .btn-group.w-100.my-3(
                v-if="screen.type !== 'information' && !isActive"
            )
                button.btn.btn-outline-secondary(
                    v-bind:class="{ active: !screen.bonus }"
                    @click="screen.bonus = false"
                ) Standard
                button.btn.btn-outline-secondary(
                    v-bind:class="{ active: screen.bonus }"
                    @click="screen.bonus = true"
                ) Bonus
            
            .form-group.multi-choice(
                v-if="(screen.type === 'mc_single' || screen.type === 'mc_multiple') && !isActive"
            )
                label Multiple Choice Options:
                .input-group.mb-2(
                    v-for="(option, index) in screen.options"
                )
                    .input-group-prepend
                        span.input-group-text {{ index + 1 }}

                    input.form-control(
                        type="text"
                        v-model="option.text"
                    )

                    .input-group-append
                        span.input-group-text: i.fas.fa-times(
                            @click="removeOption(option, index)"
                        )

                    .correct-indicator
                        i.fas.fa-check-circle.text-success(
                            v-show="option.is_correct"
                            @click="toggleCorrect(option)"
                        )
                        i.far.fa-circle(
                            v-show="!option.is_correct"
                            @click="toggleCorrect(option)"
                        )
                .row.my-3
                    .col-12: button.btn.btn-outline-secondary.py-2.text-center.w-100(
                        @click="addOption"
                    ) +
            
            .form-group(
                v-if="screen.type === 'text' && !isActive"
            ): write-in-editor( 
                :screen="screen"
                @text-updated="textUpdated"
            )
            
            .form-group
                label Button Text
                input.form-control(
                    type="text"
                    v-model="screen.button_text"
                )


</template>

<script>
import { VueEditor } from "vue2-editor"
import PhotoControls from './PhotoControls'
import WriteInEditor from './WriteInEditor'

export default {

    props: {
        screen: { type: Object, required: true },
        isActive: { type: Boolean, required: true }
    },

    components: {
        PhotoControls, WriteInEditor
    },

    data() {
        return {
            toolbar: [["bold", "italic", "underline"]],
        }
    },

    computed: {
        amtCorrect() {
            return this.screen.options.filter( option => option.is_correct ).length
        }
    },

    methods: {
        selectScreenType( type ) {
            if( this.isActive ) return // Can't do this to live tours
            this.screen.type = type
            this.screen.options = [
                {
                    text: 'Cheers!',
                    is_correct: true
                }
            ]
        },

        addOption() {
            this.screen.options.push({
                text: 'Answer Text',
                is_correct: false
            })
        },

        removeOption( option, index ) {
            // If only option, do no remove
            if( this.screen.options.length === 1 ) return

            // If option has an id, emit event to track deletion
            if( option.id ) {
                this.$emit('delete-option', option)
            }

            // Remove
            this.screen.options.splice( index, 1 )
        },

        contentChanged( delta, oldDelta, source ) {
            // This might be wonky, but there is an issue with insane line breaks on old screens
            // Going to try and yank all empty <p>+<br> tags
            let fragments = this.screen.content.split('<p><br></p>')
            this.screen.content = fragments.join('')
        },

        textUpdated( text ) {
            this.screen.options[0].text = text
            this.$emit( 'screen-updated' )
        },

        toggleCorrect( option ) {
            option.is_correct = !option.is_correct
            
            // Detect mc type
            if( this.amtCorrect > 1 ) this.screen.type = 'mc_multiple'
            else this.screen.type = 'mc_single'
        }
    }
    
}
</script>

<style lang="scss">
    .page-type-option.disabled {
        filter: saturate(75%);
        opacity: 0.9;
        cursor: not-allowed !important;
    }

    .form-group.multi-choice {
        min-height: 380px;
    }

    i.fas.fa-times {
        cursor: pointer;
    }
</style>