<template lang="pug">
    .col-md-8.text-center.tour-content
    
        h1 {{ screen.headline }}

        .row.justify-content-center

            .col-md-8.col-11.mb-4( v-if="screen.img" ): progressive-img.img-fluid(
                :src="screen.img"
            )

            .col-md-8.col-11
                p( v-html="screen.content" )

        .row.form-group.justify-content-center.mt-4( v-if="isCaptain" )
            
            .col-md-8.col-11
                button.btn.btn-submit.btn-success.w-100(
                    @click="continueTour()"
                    ref="answerButton"
                ) {{ screen.button_text }}

</template>

<script>
// Packages
import swal from 'sweetalert2'

// Services
import { nextScreen } from '../services'

export default {
    props: {
        screen: { type: Object, required: true },
        isCaptain: { type: Boolean, required: true }
    },

    data() {
        return {
            buttonDisabled: false, // To prevent accidental click ahead
        }
    },

    methods: {
        
        // Notify server, continue tour
        continueTour() {
            // Set button to disabled to prevent accidental click-aheads
            this.buttonDisabled = true

            // Submit to backend
            nextScreen()
            .then( res => {console.log('advancing to next screen: ', res)} )
            .catch( err => {
                swal({
                    title: 'An error has occured!',
                    text: 'Please try again.',
                    type: 'error',
                    timer: 2500
                })
            })
            .finally( () => {
                setTimeout( () => this.buttonDisabled = false, 1000 )
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

