<template lang="pug">

    #screen-window.col-10.bg-white: .row

        .col-12.bg-primary.py-2( v-bind:style="{ backgroundColor: `${tenant.primary_color} !important` }" ): img.logo( :src="tenant.logo" )
        .col-12.bg-dark.py-1.text-white {{ headline }}

        .col-12.mb-4: img.img-fluid( src="/correct" )

        #content.col-12.pt-3.mb-4( v-html="content" )

        #button.col-12.pb-4.mb-2
            button.btn.btn-submit.btn-success.w-100 Get Started


</template>

<script>
export default {
    props: {
        headline: { type: String, required: true },
        content: { type: String, required: true },
        tenant: { type: Object, required: true }
    }
}
</script>

<style lang="scss">

    #email-window {
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.8);
        background: white;

        img.logo {
            max-height: 50px;
        }

        .option {
            width: 100%;
            border: 2px solid #333;
            font-size: 22px;
            margin-bottom: 10px;
            line-height: 22px;
            padding: 15px 10px;
            font-family: bebas-neue, sans-serif;
        }

        .option.active {
            background: #FEDC01;
            border-color: #FEDC01;
        }

        #content {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

</style>