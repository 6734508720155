<template lang="pug">
  
    #write-in
        .row
            .col-12: label Write In Answer:

            .col-12: input.form-control.mb-2(
                v-model="text"
                @keyup="textChanged"
                @change="textChanged"
            )

            .col-12: .row( v-for="(grouping, groupingIndex) in characters" style="margin: 0px -5px 5px;" )
                .col.text-center( v-for="(character, index) in grouping" :key="`${character}-${index}`" )
                    input.character.form-control.mb-3(
                        :ref="`${groupingIndex}-${index}`"
                        type="text"
                        maxlength="1"
                        readonly
                        :value="character"
                        v-bind:class="{ 'non-alphanumeric': isNonAlphaNumeric( character ) }"
                    )

</template>

<script>
import moment from 'moment'

export default {

    props: {
        screen: { type: Object, required: true }
    },

    data() {
        return {
            localScreenInstance: this.screen,
            text: 'Cheers!',
            refreshKey: 'hi'
        }
    },

    watch: {
        screen: {
            deep: true,
            handler(screen) {
                this.localScreenInstance = this.screen
                this.text = this.localScreenInstance.options[0] ? this.localScreenInstance.options[0].text : 'Cheers!'
            }
        }
    },

    computed: {
        characters() {
            return this.text.split( ' ' ).map( grouping => grouping.split( '' ) )
        }
    },

    methods: {
        isNonAlphaNumeric( character ) {
            return !character.match( /^[0-9a-zA-Z]+$/ )
        },

        textChanged( event ) {
            this.$emit( 'text-updated', this.text )
        }
    },

    created() {
        this.localScreenInstance = this.screen
        this.text = this.localScreenInstance.options[0] ? this.localScreenInstance.options[0].text : 'Cheers!'
    }
    
}
</script>

<style lang="scss">
    #write-in {
        min-height: 380px;
        
        .col {
            max-width: 50px;
            padding-left: 5px;
            padding-right: 5px;
        }

        input.character {
            padding: 0;
            text-transform: uppercase;
            text-align: center;
        }

        input[readonly].non-alphanumeric {
            background-color: #ccc;
        }

        input[readonly] {
            background: white;
        }
    }
</style>