import Lightpick from 'lightpick'
import moment from 'moment'

export default () => {
    let $datepicker = $( '.datepicker' )

    if( $datepicker[0] ) {
        let picker = new Lightpick({
            field: $datepicker[0],
            singleDate: false,
            maxDate: moment(),
            format: 'MM/DD/YYYY',
            onOpen: () => {
                // Clear data
                picker.reset()
                // Need to set flag for opened to avoid redirect loop on init
                $datepicker.data( 'touched', true )
            },
            onSelect: ( min, max ) => {
                if( max && min && $datepicker.data( 'touched' ) ) {
                    let formattedMin = moment( min ).format( 'YYYY-MM-DD' )
                    let formattedMax = moment( max ).format( 'YYYY-MM-DD' )
                    location.href = `?min=${formattedMin}&max=${formattedMax}`
                }
            } 
        })
        
        if( $datepicker.data( 'init-min' ) && $datepicker.data( 'init-max' ) && $datepicker.data( 'init-custom' ) ) {
            let min = moment( $datepicker.data( 'init-min' ) ).format( 'MM/DD/YYYY' )
            let max = moment( $datepicker.data( 'init-max' ) ).format( 'MM/DD/YYYY' )
            
            // Set date range
            picker.setDateRange( min, max )
        }
    }
}