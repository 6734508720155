<template lang="pug">
  
    #builder-menu.col-12.py-2.pl-4: .row
        .col-6
            span.mr-2
                strong( v-show="tourVersion.tour.active_version_id" ) Live Tours
                strong( v-show="!tourVersion.tour.active_version_id" ) Draft Tours
                | &nbsp;/ {{ tourVersion.tour.name }} / {{ tourVersion.version_number }}
            span.badge.badge-secondary( v-show="isActive" ) ACTIVE
            span.badge.badge-secondary( v-show="!isActive" @click="setToLive" ): a.text-white( href="#" style="text-decoration: none;" ) SET TO LIVE
        
        .col-6.text-right
            button.btn.btn-outline-dark.mr-3(
                v-show="tourVersion.tour.active_version_id"
                @click="openSaveAsModal"
            ) Save As
            button.btn.btn-outline-dark.mr-3(
                @click="saveChanges"
            ) Save Changes
            
            //- Toggle page, email, leaderboard/referral editors
            button.btn.btn-white.mr-2(
                v-bind:class="{ 'active': currentEditor === 'screen' }"
                @click="changeEditor( 'screen' )"
            ): i.fas.fa-edit

            button.btn.btn-white.mr-2(
                v-bind:class="{ 'active': currentEditor === 'email' }"
                @click="changeEditor( 'email' )"
            ): i.fas.fa-envelope

            button.btn.btn-white(
                v-bind:class="{ 'active': currentEditor === 'referral' }"
                @click="changeEditor( 'referral' )"
            ): i.fas.fa-trophy

            save-as-modal(
                @save-as="saveAs"
            )

</template>

<script>
import SaveAsModal from './SaveAsModal'

export default {

    props: {
        tourVersion: { type: Object, required: true },
        currentEditor: { type: String, required: true },
        isActive: { type: Boolean, required: true }
    },

    components: {
        SaveAsModal
    },

    methods: {
        changeEditor( editor ) {
            this.$emit( 'editor-changed', editor )
        },

        openSaveAsModal() {
            this.$bvModal.show( 'saveAsModal' )
        },

        saveChanges() {
            this.$emit( 'save-changes' )
        },

        saveAs( version ) {
            this.$emit( 'save-as', version )
        },

        setToLive() {
            let c = confirm('Set this version as the live tour?')
            if( c ) {
                window.location.href = `/set-draft-to-live/${this.tourVersion.tour_id}/${this.tourVersion.id}`
            }
        }
    }
    
}
</script>

<style lang="scss">

    #builder-menu {
        z-index: 2;
        background: #f8e99d;
        box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.8);

        span {
            font-family: ibm-plex-mono,sans-serif;
            text-transform: uppercase;
            font-size: 17px;
            padding-top: 9px;
            margin-top: 5px;
        }

        span.badge {
            padding: 8px 10px;
            font-size: 13px;
        }

        .btn-outline-dark {
            min-width: 150px;
            border-radius: 100px;
            text-transform: uppercase;
            font-family: ibm-plex-mono,sans-serif;
            padding: 3px 5px 6px;
            border-width: 2px;
            font-weight: 700;
        }

        .btn-white {
            background: white;
            color: black;
            border-radius: 200px;
            padding: 6px 10px;
        }

        .btn-white.active {
            background: black;
            color: white;
        }
    }

</style>