<template lang="pug">
    .col-md-8.text-center.tour-content
    
        h1 Choose Your Captain

        .row.justify-content-center

            .col-md-8.col-11
                p 
                    | Is everyone logged in? Okay. Before we get started, you'll need to picky your Captain.
                p
                    | The Captain is responsible for reading the clues out to the group and inputting the team's final answers.
                p
                    | It might not sound like much but it's kind of a big deal. Pick someone you can trust. And, ideally, someone who can read.

            
        .row.form-group.justify-content-center.mt-4
            .col-md-8.col-11
                p: strong Choose A Captain:
                b-form-radio-group.w-100(
                    buttons
                    stacked
                    size="lg"
                    v-model="selectedCaptain"
                    name="selected-captain"
                    button-variant="default"
                    class="btn-multi"
                ): b-form-radio(
                    v-for="user in team.users"
                    @change="captainOptionSelected( user )"
                    :key="user.id"
                    :value="user"
                    :disabled="user.pending_invitation || !isCaptain"
                ) {{ user.email }}
                p.mt-2 Users must be logged in to be captain.
        
        .row.form-group.justify-content-center.mt-4
            
            .col-md-8.col-11( v-if="isCaptain" )
                button.btn.btn-submit.btn-success.w-100( @click="selectCaptainAndContinue( selectedCaptain, team.name )" ) Next


</template>

<script>
// Packages
import swal from 'sweetalert2'

// Services
import { selectCaptain } from '../services'

export default {
    props: {
        team: { type: Object, required: true},
        status: { type: Object, required: true },
        isCaptain: { type: Boolean, required: true },
        captainOptionSelected: { type: Function, required: true }
    },

    watch: {
        team: {
            handler( newValue ) {
                this.selectedCaptain = newValue.users.find( user => user.id === newValue.captain_id)
            },
            deep: true
        }
    },

    data() {
        return {
            selectedCaptain: this.team.users.find( user => user.id === this.team.captain_id)
        }
    },

    methods: {
        selectCaptainAndContinue( captain, teamname ) {
            
            // Select captain, continue to next step
            selectCaptain( captain, teamname )
            .then(res => {
                console.log( 'team name set to:', teamname )
                console.log( 'captain has been selected:', captain)
            })
            .catch(err => {
                swal({
                    title: 'An error has occured!',
                    text: 'Please try again.',
                    type: 'error',
                    timer: 2500
                })
            })
        }
    },
}
</script>

<style lang="scss" scoped>

</style>

