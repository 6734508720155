<template lang="pug">
    .col-md-8.text-center.tour-content
    
        h1 Congratulations

        .row.justify-content-center

            .col-md-8.col-11: progressive-img.img-fluid(
                src="/correct"
            )

        .row.justify-content-center

            .col-md-8.col-11
                p: strong
                    | Your final score is {{ status.score }}.
                p 
                    | You've completed {{ tenant.name }} {{ tour.name }}! Checkout the leaderboard to see where you stack up!
        
        .row.form-group.justify-content-center.mt-4
            
            .col-md-8.col-11
                a.btn.btn-submit.btn-success.w-100( v-if="tour.subdomain !== 'demo'" @click="triggerFeedback" ) See The Leaderboard
                a.btn.btn-submit.btn-success.w-100( v-if="tour.subdomain === 'demo'" href="/" ) See The Leaderboard


</template>

<script>
// Packages
import swal from 'sweetalert2'

export default {
    props: {
        team: { type: Object, required: true },
        status: { type: Object, required: true },
        tour: { type: Object, required: true },
        triggerFeedback: { type: Function, required: true },
        tenant: { type: Object, required: true }
    },
}
</script>

<style lang="scss" scoped>

</style>

