<template lang="pug">

    b-modal#change-captain(
        ref="changeCaptain"
        title="Change Captain"
        :centered="true"
        :hide-footer="true"
    )

        div.justify-content-center.text-center
            
            p( v-if="isCaptain" ) You are already the captain!

            p( v-if="!isCaptain" )
                | {{ captain.name }} is currently the captain.
                br
                strong Do you want to takeover as captain?
        

        div.justify-content-center.text-center.mt-5.mb-2
            b-btn(
                size="lg"
                v-if="isCaptain"
                @click="closeModal"
            ) Close

            b-btn.btn-success.mr-3.controls(
                size="lg"
                v-if="!isCaptain"
                @click="changeCaptain"
            ) Yes

            b-btn.controls(
                size="lg"
                v-if="!isCaptain"
                @click="closeModal"
            ) No

</template>

<script>
// Packages
import swal from 'sweetalert2'

// Services
import { selectCaptain } from '../services'

export default {
    props: {
        team: { type: Object, required: true },
        user: { type: Object, required: true },
        isCaptain: { type: Boolean, required: true }
    },

    computed: {
        captain() {
            return this.team.users.find( (user) => { return this.team.captain_id === user.id } )
        }
    },

    methods: {
        changeCaptain() {
            // Close modal, trigger captain change
            this.$refs.changeCaptain.hide()
            
            // Fire captain select to server
            selectCaptain( this.user )
            .then( res => console.log('captainship taken', res) )
            .catch( err => {
                swal({
                    title: 'An error has occured!',
                    text: 'Please try again.',
                    type: 'error',
                    timer: 2500
                })
            })
        },

        closeModal() {
            this.$refs.changeCaptain.hide()
        }
    }
}
</script>

<style lang="scss">

    #change-captain {
        .modal-header, .modal-body {
            display: block;
        }

        button.close {
            display: none;
        }

        h5.modal-title {
            font-size: 40px;
            text-align: center;
        }

        .btn.controls {
            min-width: 130px;
            font-weight: 600;
        }
    }

</style>

