// Packages
import swal from 'sweetalert2'

export default {
    computed: {
        currentQuestionNumber() {
            // Filter questions by not bonus + not type 'intro' + not type 'transition'
            return this.screens
                    .filter( screen => {
                        if(
                            screen.bonus ||
                            screen.type === 'information'
                        ) return false

                        return true
                    })
                    .findIndex( screen => screen.id === this.currentScreen.id ) + 1
        },

        currentScreen() {
            if( this.status.current_stage === 'QUESTIONS' ) {
                return this.screens[ this.status.current_screen ]
            } else {
                return {
                    type: null
                }
            }
        }
    }
}