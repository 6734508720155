<template lang="pug">
  
    .row.referral-editor

        .col-6
            .btn-header.w-100.py-2.mb-3.text-center(
                v-bind:class="{ active: currentFocus === 'leaderboard' }"
            )
                label Leaderboard

            .form-group
                label Leaderboard Title
                input.form-control(
                    type="text"
                    v-model="tourVersion.tour.leaderboard_title"
                    @focus="editorFocused"
                    data-editor="leaderboard"
                    @change="editorChange"
                )
        
        .col-6
            .btn-header.w-100.py-2.mb-3.text-center(
                @click="refreshPopup"
                v-bind:class="{ active: currentFocus === 'referral' }"
            )
                label Referral Popup

            .form-group
                label.refresh-btn Referral Headline:
                    small(
                        @click="refreshPopup"
                    )
                        i.fa.fa-redo
                        | &nbsp;Refresh popup
                input.form-control(
                    type="text"
                    v-model="tourVersion.tour.popup.headline"
                    @focus="editorFocused"
                    data-editor="referral"
                    @change="editorChange"
                )
            
            .form-group
                label.refresh-btn Top Content:
                    small(
                        @click="refreshPopup"
                    )
                        i.fa.fa-redo
                        | &nbsp;Refresh popup
                vue-editor(
                    id="teammate-editor"
                    v-model="tourVersion.tour.popup.top_content"
                    :editor-toolbar="toolbar"
                    @focus="editorFocused"
                    data-editor="referral"
                    @text-change="editorChange"
                )
            
            .form-group
                label.refresh-btn Referral Tour:
                    small(
                        @click="refreshPopup"
                    ): i
                        i.fa.fa-redo
                        | &nbsp;Requires refresh
                select.form-control(
                    v-model="tourVersion.tour.popup.referral_tour_id"
                    @focus="editorFocused"
                    data-editor="referral"
                    @change="editorChange"
                )
                    option(
                        v-for="tour in availableTours"
                        :key="tour.id"
                        :value="tour.id"
                    ) {{ tour.name }}
            
            .form-group
                label.refresh-btn Discount Code:
                    small(
                        @click="refreshPopup"
                    )
                        i.fa.fa-redo
                        | &nbsp;Refresh popup
                input.form-control(
                    type="text"
                    v-model="tourVersion.tour.popup.discount_code"
                    @focus="editorFocused"
                    data-editor="referral"
                    @change="editorChange"
                )
            
            .form-group
                label.refresh-btn Content:
                    small(
                        @click="refreshPopup"
                    )
                        i.fa.fa-redo
                        | &nbsp;Refresh popup
                vue-editor(
                    id="teammate-editor"
                    v-model="tourVersion.tour.popup.content"
                    :editor-toolbar="toolbar"
                    @focus="editorFocused"
                    data-editor="referral"
                    @text-change="editorChange"
                )

            .form-group
                label.refresh-btn Button Text:
                    small(
                        @click="refreshPopup"
                    )
                        i.fa.fa-redo
                        | &nbsp;Refresh popup
                input.form-control(
                    type="text"
                    v-model="tourVersion.tour.popup.button_text"
                    @focus="editorFocused"
                    data-editor="referral"
                )
            


</template>

<script>
import moment from 'moment'

export default {

    props: {
        availableTours: { type: Array, required: true },
        tourVersion: { type: Object, required: true },
        currentFocus: { type: String, required: true }
    },

    data() {
        return {
            toolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]],
        }
    },

    methods: {
        editorFocused( e ) {
            console.log(e)
            this.$emit( 'referral-focused', e.target ? e.target.dataset.editor : 'referral' )
            // Refocus
            setTimeout(() => {
                e.target ? e.target.focus() : e.container.children[0].focus()

            },300)
        },

        refreshPopup() {
            this.$emit( 'referral-updated', moment().unix() )
        },

        editorChange() {
            //this.$emit( 'email-updated', moment().unix() )
        }
    }
    
}
</script>

<style lang="scss">
    .referral-editor {
        height: calc(100vh - 200px);
        padding-bottom: 30px;

        .ql-editor {
            max-height: 300px;
        }
    }

    .refresh-btn {
        width: 100%;

        small {
            cursor: pointer;
            float: right;
            padding-top: 3px;
        }
    }
</style>