<template lang="pug">

    .col-md-8.text-center.tour-content

        h1( v-if="isCaptain && tour !== 'demo'" ) Invite your friends
        h1( v-if="isCaptain && tour === 'demo'" ) Welcome to your demo tour
        h1( v-if="!isCaptain" ) You're Logged In

        .row.justify-content-center
            
            .col-md-8.col-10.px-0
                p( v-if="isCaptain && tour !== 'demo'" ) Brews & Clues is better with friends! Use the QR code below to add players to your team.
                
                p( v-if="isCaptain && tour === 'demo'" ) This demo tour is designed to highlight how Brews & Clues works and give you a taste of what to expect on a real tour.
                p( v-if="isCaptain && tour === 'demo'" ) During real tours, you’ll receive detailed directional language to guide you throughout the city.
                p( v-if="isCaptain && tour === 'demo'" ) For this virtual demo, instead of guiding you through just one city, we’ll highlight a few of our favorite destinations across the country.
                p( v-if="isCaptain && tour === 'demo'" ) Click the button below to get started.

                p( v-if="!isCaptain" ) Let's invite the rest of your team using the QR code below. Once the captain starts the tour, you'll be able to hit 'Get Started' below.
                
                div.mb-4( v-if="tour !== 'demo'" v-html="qrCode" )

                form( method="POST" :action="route" aria-label="Register Your Team" v-if="isCaptain" )

                    input( name="_token" type="hidden" :value="csrf" )
                    
                    .row.justify-content-center( v-if="tour !== 'demo'" )
                        .col-md-8.col-10.px-0
                            p 
                                strong Not sure how to use the QR code?
                                br
                                | Don't worry—we got you covered. You can also invite players to your team by adding their email addresses below.
                    
                    .row.form-group.justify-content-center.mt-2.mb-3( v-for="email in emails" v-if="isCaptain" v-show="tour !== 'demo'" ): .col-md-8.col-10.px-0
                        input.form-control(
                            type="email"
                            name="teammates[]"
                            placeholder="Teammate Email"
                        )
                    
                    .row.form-group.justify-content-center.mt-2.mb-3(v-if="isCaptain && tour !== 'demo'"): .col-md-8.col-10.px-0
                        button.btn.btn-secondary.add-players(
                            @click="addEmail"
                            v-show="hasMoreSeats"
                        ) ADD ADDITIONAL PLAYERS

                    .row.form-group.mt-5.mb-0.justify-content-center: .col-md-8.col-10.px-0
                        button.btn.btn-success.btn-submit.w-100(
                            type="submit"
                            v-if="tour !== 'demo'"
                        ) Start Your Tour

                        button.btn.btn-success.btn-submit.w-100(
                            type="submit"
                            v-if="tour === 'demo'"
                        ) Let's Do This!
                    
                a.btn.btn-success.btn-submit.w-100(
                    v-if="!isCaptain"
                    @click="proceedToTour"
                    :class="{ 'disabled-btn': !enableButton, 'animate__animated animate__pulse animate__slow animate__infinite': enableButton }"
                ) Get Started

</template>


<script>
// Packages
import swal from 'sweetalert2'
import axios from 'axios'

// Tour channel events/handlers
import events from '../Tour/mixins/events'
import listeners from '../Tour/mixins/listeners'

export default {
    props: {
        tour: { type: String, required: true },
        team: { type: Object, required: true },
        route: { type: String, required: true },
        csrf: { type: String, required: true },
        isCaptain: { type: Boolean, required: true },
    },

    mixins: [
        events, listeners
    ],

    data() {
        return {
            // Going to default to seats-1 to start the form 
            // Since we're doing a traditional form submission,
            // these won't actually hold anything
            emails: new Array(this.team.seats ? this.team.seats - 1 : 4),
            qrCode: null,
            enableButton: false,
            
            // Team Channel (https://laravel.com/docs/7.x/broadcasting)
            teamChannel: this.$echo.join( `App.Team.${this.team.id}` ),
        }
    },

    computed: {
        spotsAvailable() {
            return this.team.seats ? this.team.seats - 1 : 'UNLIMITED'
        },

        hasMoreSeats() {
            return this.team.seats ? this.emails.length < this.team.seats - 1 : true
        }
    },

    methods: {
        addEmail( e ) {
            e.preventDefault()
            this.emails.push( '' )
        },

        proceedToTour() {
            if( this.enableButton ) {
                window.location.href = '/'
            }
        }
    },

    created() {
        axios.get('/tour/qr/170')
        .then(({data}) => {
            this.qrCode = data.qr_code
        })
        .catch(err => console.log(err))
    },

    mounted() {
        // Listen to team channel, listen for events (definitions in ./mixins/listeners.js)
        this.teamChannel
            // Server-based event Listeners
            .listen( 'UserJoinedTour', this.userJoinedTour )
            .listen( 'StatusUpdated', ({ status }) => {
                console.log(status)
                if( status.current_stage !== 'NOT_STARTED' ) {
                    this.enableButton = true
                }
            })

        // Fire create team name modal, if captain
        if( this.isCaptain ) {
            swal.fire({
                customClass: 'answer-feedback-modal',
                title: 'Create a Team Name',
                html: '<p style="line-height: 29px;font-size: 18px;">No trivia game is complete without a team name. Put your heads together and come up with a name worthy of the leaderboard.</p>',
                input: 'text',
                confirmButtonText: 'Create My Team',
                inputValue: this.team.name,
                showCancelButton: false,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to write something!'
                    }
                },
                preConfirm: (name) => {
                    // Post team name
                    return axios.post('/set-team-name', {name})
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .disabled-btn {
        opacity: .5;
    }
</style>

