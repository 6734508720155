<template lang="pug">
    .col-md-8.text-center.tour-content

        h1( v-show="screen.headline" ) {{ screen.headline }}
        h1( v-show="!screen.headline && !screen.bonus" ) Question {{ currentQuestionNumber }}
        h1( v-show="!screen.headline && screen.bonus" ) Bonus Question

        .row.justify-content-center

            .col-md-8.col-11.mb-4( v-if="screen.img" ): progressive-img.img-fluid(
                :src="screen.img"
            )

            .col-md-8.col-11
                p( v-html="screen.content" )
        
        
        #write-in.row.form-group.justify-content-center.mt-2
            
            .col-md-8.col-11.mb-4
                .text 
                    .col-12: .row( v-if="rawInput[0]" v-for="(grouping, groupingIndex) in rawInput" style="margin: 0px -5px 5px;" )
                        .col.text-center( v-for="(character, index) in grouping" )
                            input.character.form-control.mb-3(
                                :ref="`${groupingIndex}-${index}`"
                                type="text"
                                maxlength="1"
                                pattern="[a-zA-Z0-9]+"
                                @change="textUpdated"
                                @keyup="tabAhead"
                                :value="isNonAlphaNumeric( character ) ? character : ''"
                                :readonly="!isCaptain || isNonAlphaNumeric( character )"
                                v-bind:class="{ 'non-alphanumeric': isNonAlphaNumeric( character ) }"
                                :data-grouping="groupingIndex"
                                :data-index="index"
                            )
    
            .col-md-8.col-11( v-if="isCaptain" )
                button.btn.btn-submit.btn-success.w-100(
                    @click="answerQuestion()"
                    ref="answerButton"
                    :disabled="!readyToSubmit"
                ) {{ screen.button_text }}


</template>

<script>
// Packages
import swal from 'sweetalert2'

// Services
import { answerQuestion } from '../services'

export default {
    props: {
        screen: { type: Object, required: true },
        isCaptain: { type: Boolean, required: true },
        response: { required: true },
        currentQuestionNumber: { type: Number, required: true },
        optionSelected: { type: Function, required: true }
    },

    data() {
        return {
            textInput: '',
            rawInput: [],
            readyToSubmit: false,
            buttonDisabled: false, // To prevent accidental click ahead
            t: null // Timeout to throttle text input transmission
        }
    },

    computed: {
        characters() {
            return this.screen.options[0].text.split( ' ' ).map( grouping => grouping.split( '' ) )
        }
    },

    watch: {
        response: {
            handler( newValue ) {
                if( !this.isCaptain ) this.rawInput = newValue
                this.rawInput.forEach( ( grouping, groupingIndex ) => {
                    grouping.forEach( ( character, index ) => {
                        this.$refs[`${groupingIndex}-${index}`][0].value = character
                    })
                })
            },
            deep: true
        }
    },

    methods: {
        // Workaround for previous value being reported
        multiChange() {
            this.$nextTick( () => {
                this.optionSelected( this.selectedOptions )
            })
        },

        // Throttled transmission of input text to teammates
        textUpdated( { target } ) {
            let grouping = parseInt( target.dataset.grouping )
            let index = parseInt( target.dataset.index )
            
            // Build raw input
            this.rawInput[grouping][index] = target.value

            // Set ready to submit
            this.readyToSubmit = !this.rawInput.flat().includes('')

            clearTimeout( this.t )
            this.t = setTimeout(() => {
                this.optionSelected( this.rawInput )
            }, 1000)
        },

        tabAhead( { target, key } ) {
            let grouping = parseInt( target.dataset.grouping )
            let index = parseInt( target.dataset.index )

            // Check that key is 1 character in length (otherwise it's a key like 'Shift', or 'Enter')
            // and also check for alpha numeric...technically they should only be allowed to key in that
            // but chrome ignores
            let tabAhead = key.length === 1 && !this.isNonAlphaNumeric( key )

            if( tabAhead ) {
                // Check if another input in row, if not go to next grouping
                if( this.$refs[`${grouping}-${index+1}`] ) {
                    this.$refs[`${grouping}-${index+1}`][0].focus()
                } else if( this.$refs[`${grouping+1}-0`] ) {
                    this.$refs[`${grouping+1}-0`][0].focus()
                }
            }
        },

        isNonAlphaNumeric( character ) {
            if( character === '' ) return false
            return !character.match( /^[0-9a-zA-Z]+$/ )
        },

        // Send answer to server
        answerQuestion() {
            
            let words = this.rawInput.map( grouping => grouping.join('') )
            let response = words.join(' ')

            // Set button to disabled to prevent accidental click-aheads
            this.buttonDisabled = true

            answerQuestion( response, this.screen.id )
            .then( res => {console.log('answer submitted successfully', res)} )
            .catch( err => {
                swal({
                    title: 'An error has occured!',
                    text: 'Please try again.',
                    type: 'error',
                    timer: 2500
                })
            })
            .finally( () => {
                setTimeout( () => this.buttonDisabled = false, 1000 )
            })
        }
    },

    created() {
        // Initialize raw input based off answer w/o alphanumeric
        this.rawInput = this.characters.map( grouping => (
            grouping.map( character => this.isNonAlphaNumeric( character ) ? character : '' )
        ))
    },

    updated() {
        // Prevent weird overwrite on re-draw
        this.rawInput.forEach( ( grouping, groupingIndex ) => {
            grouping.forEach( ( character, index ) => {
                this.$refs[`${groupingIndex}-${index}`][0].value = character
            })
        })
    }
}
</script>

<style lang="scss" scoped>

    #write-in {
        min-height: 150px;
        justify-content: center;

        input {
            border-radius: 2px;
            max-width: 25px;
        }

        .row {
            justify-content: center;
        }
    }

    .text {
        display: flex;
    }

    .non-alphanumeric {
        background: #eee;
    }

</style>

