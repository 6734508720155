<template lang="pug">
    div
        portal( to="tour-sidebar" )
            transition-group( name="fade" )
                i#toggle-sidebar(
                    key="toggler" 
                    @click="toggleSidebar"
                    :class="{ 'fa fa-bars': !isVisible, 'fa fa-times': isVisible }"
                )
                #tour-sidebar(
                    key="menu"
                    :class="{ 'visible': isVisible }"
                )
                    #tour-name.container.bg-black
                        .row.w-100: .col-12.text-left {{ tour.name }}
                    
                    #tour-info.container.bg-white
                        .row.w-100: .col-12.text-left.flex
                            label Team Name
                            span {{ team.name }}
                        
                        .row.w-100
                            .col-6.text-left.flex
                                label Current Score
                                span {{ status.score }}
                            .col-6.text-left.flex
                                label Progress
                                span {{ percentComplete }}
                    
                    #tour-controls.container.bg-black
                        .row.w-100( v-show="tour.subdomain !== 'demo'" ): .col-12.text-left( v-b-modal="'change-captain'" )
                            span Change Captain
                        
                        .row.w-100( v-show="tour.subdomain !== 'demo'" ): .col-12.text-left( v-b-modal="'add-players'" )
                            span Add Player
                        
                        .row.w-100: .col-12.text-left( @click="openSupportPopup" )
                            span Support & FAQs
                        
                        .row.w-100: .col-12.text-left
                            a( :href="tenant.id === 1 ? 'https://www.brewsandclues.com' : 'https://www.gotrivia.com'" target="_blank" ) {{ tenant.domain }}
</template>

<script>
// Packages
import swal from 'sweetalert2'

export default {
    props: {
        team: { type: Object, required: true },
        screens: { type: Array, required: true },
        status: { type: Object, required: true },
        tour: { type: Object, required: true },
        user: { type: Object, required: true },
        isCaptain: { type: Boolean, required: true },
        tenant: { type: Object, required: true }
    },

    data() {
        return {
            isVisible: false
        }
    },

    computed: {
        percentComplete() {
            return `${(( this.status.current_screen / this.screens.length  ) * 100).toFixed(0)}%`
        }
    },

    methods: {
        toggleSidebar() {
            this.isVisible = !this.isVisible
        },
        openSupportPopup() {
            swal.fire({
                customClass: 'support-popup',
                title: "Support & FAQs",
                html: `
                    <p>If you don't find your question answered below, send an email to <a href="mailto:${this.tenant.email}" style="color:black;"><strong>${this.tenant.email}</strong></a>.</p>
                    <p><strong>We got logged out. How do we get back to the tour?</strong><br/><span>+</span>Easy peasy. Head to your initial tour activation email and click on the "Get Started" link. You'll log back in and pick up right where you left off.</p>
                    <p><strong>It started raining—can we stop our tour and continue tomorrow?</strong><br/><span>+</span>Of course. Just stop playing—you can continue your tour anytime. Just log back in with your original tour activation email.</p>
                    <p><strong>How do we find the Leaderboard?</strong><br/><span>+</span>Once you complete the tour, you'll unlock the Leaderboard for your location. Good luck!</p>
                `,
                confirmButtonText: 'OK'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    #toggle-sidebar {
        font-size: 1.75rem;
        position: fixed;
        top: 20px;
        left: 20px;
    }

    #tour-sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 72px;
        left: -93vw;
        width: 90vw;
        height: calc(100% - 72px);
        background: white;
        transition: ease-in-out 350ms;
        box-shadow: 3px 9px 19px -6px #000;

        .row {
            margin-left: 0px;
        }

        label {
            font-size: .9rem;
            text-transform: uppercase;
            color: #F4CB00;
            font-family: 'CP Mono';
            font-weight: 600;
            display: flex;
        }

        span {
            display: flex;
            font-size: 2.2rem;
            font-weight: 300;
            line-height: 2.5rem;
            font-family: 'CP Mono';
            font-weight: 200;
        }
    }

    #tour-sidebar.visible {
        left: 0px;
    }

    #tour-name {
        display: flex;
        padding: 10px 0px;
        font-size: 1rem;
        text-transform: uppercase;
        color: #F4CB00;
        font-family: 'CP Mono';
        font-weight: 600;
        justify-content: flex-start;
    }

    #tour-info {
        display: flex;
        flex-grow: 1;
        padding: 25px 0px;
        align-items: flex-start;
        align-content: baseline;

        .row {
            padding-bottom: 40px;
        }

        span {
            line-break: auto;
            word-break: break-word;
            //overflow-wrap: anywhere; 
        }
    }

    #tour-controls {
        padding: 0px;

        *, *:focus, *:active {
            outline: none !important;
            outline-color: transparent !important;
        }

        div.row {
            padding: 10px 5px;
            border-bottom: white 2px solid;
            
            span {
                color: white;
                font-size: .95rem;
                font-weight: 400;
                text-transform: uppercase;
            }
        }

        div.row:last-child {
            padding: 12px 5px;
            border-bottom: none;
            background: #F4CB00;

            a {
                color: black;
                font-size: .95rem;
                font-weight: 600;
                text-transform: lowercase;
                font-family: 'CP Mono';
            }
        }
    }

    .bg-black {
        background: black;
    }
</style>