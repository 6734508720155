<template lang="pug">
    b-modal(
        id="saveAsModal"
        ref="saveAsModal"
        title="Save As..."
        :centered="true"
        :hide-footer="true"
    )   

        form
            b-form-input.mb-3(
                v-model="version"
                type="text"
                placeholder="ex. 1.1"
            )


        div.justify-content-center.text-center.mt-5.mb-2
            b-btn.btn-submit.btn-success.w-100(
                size="lg"
                @click.prevent="triggerSave"
                :disabled="!version"
            ) Save As
</template>

<script>
export default {
    data() {
        return {
            version: null
        }
    },

    methods: {
        triggerSave() {
            this.$emit( 'save-as', this.version )
        }
    }
}
</script>

<style>

</style>