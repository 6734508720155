
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require( './bootstrap' )

window.Vue = require( 'vue' )

const bugsnag = require( 'bugsnag-js' )
const bugsnagClient = bugsnag({
    apiKey: process.env.MIX_BUGSNAG_API_KEY,
    releaseStage: process.env.MIX_APP_ENV
})

const bugsnagVue = require( 'bugsnag-vue' )
bugsnagClient.use( bugsnagVue( Vue ) )

import AdminMenuToggle from './misc/AdminMenuToggle'
import ToggleLeaderboard from './misc/ToggleLeaderboard'
import DateFilter from './misc/DateFilter'

import BootstrapVue from 'bootstrap-vue'
Vue.use( BootstrapVue )

import VueProgressiveImage from 'vue-progressive-image'
Vue.use( VueProgressiveImage )

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo'
import VueEcho from 'vue-echo'

window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: true
})

Vue.use( VueEcho, window.Echo )

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component( 'tour', require( './modules/Tour/Tour.vue' ).default )
Vue.component( 'register-team', require( './modules/RegisterTeam/RegisterTeam.vue' ).default )
Vue.component( 'tour-builder', require( './modules/TourBuilder/TourBuilder.vue' ).default )

const app = new Vue({
    el: '#app'
});

// Basic UI Events (Non-Vue)
$( document ).ready( () => {
    AdminMenuToggle()
    ToggleLeaderboard()
    DateFilter()
    $( '[data-toggle="tooltip"]' ).tooltip()
})