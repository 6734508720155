<template lang="pug">

    #screen-window.col-10.bg-white( :key="refreshKey" ): .row

        .col-12.bg-primary.py-2( v-bind:style="{ backgroundColor: `${tenant.primary_color} !important` }" ): img.logo( :src="tenant.logo" )

        .col-12.py-2.text-center
            h1 {{ tourVersion.tour.leaderboard_title || `${tourVersion.tour.name} Leaderboard` }}
        
        .col-12.py-2.leaderboard-active
            .row.leaderboard-header.justify-content-center
                .col-1.text-center Place
                .col-7.text-center Team Name
                .col-3.text-center Score
            
            .leaderboard-body-wrapper: .row.leaderboard-body.justify-content-center(
                v-for="(entry, index) in leaderboard"
                :key="index"
            )
                .col-1.text-center {{ entry.ranking }}
                input.col-7.text-center(
                    type="text"
                    :value="entry.team.display_name || entry.team.name"
                    @change="displayNameUpdated"
                    :data-id="entry.id"
                )
                input.col-3.text-center(
                    type="number"
                    step="5"
                    :value="entry.score"
                    @change="scoreUpdated"
                    :data-id="entry.id"
                )
        

</template>

<script>
import swal from 'sweetalert2'
import { saveStatus } from '../services'

export default {
    props: {
        tenant: { type: Object, required: true },
        tourVersion: { type: Object, required: true },
        leaderboard: { type: Array, required: true },
        showPopup: { type: Boolean, required: true },
        refreshKey: { type: Number, required: false }
    },
    data() {
        return {
            throttle: null,
            popup: null
        }
    },
    watch: {
        showPopup(newVal, oldVal) {
            console.log('popup updated: ', oldVal, newVal)
            
            // If true, pop it up
            if(newVal) {
                this.popup = swal.fire({
                    target: '#screen-window',
                    focusConfirm: false,
                    returnFocus: false,
                    customClass: {
                        container: 'answer-feedback-modal interesante', 
                    },
                    willOpen: (e) => {
                        e.blur()
                    },
                    html: `
                        <h1>${this.tourVersion.tour.popup.headline}</h1>
                        ${this.tourVersion.tour.popup.top_content}<br/>
                        <img style="max-width:75%;margin-top: 20px;margin-bottom:20px;" src="${this.tourVersion.tour.popup.referral_tour.img}" />
                        <p style="line-height: 29px;font-size: 16px; margin-bottom:5px;">Discount code:</p>
                        <p style="line-height: 29px;font-size: 45px; font-weight: 500;font-family: 'bebas-neue'; margin-bottom: 20px;">${this.tourVersion.tour.popup.discount_code}</p>
                        ${this.tourVersion.tour.popup.content}<br/>
                        <a class="btn btn-success btn-lg w-100" style="margin-top: 30px; margin-bottom: 10px; color: black; font-weight: 500;" href="#" target="_blank" tabindex="-1">${this.tourVersion.tour.popup.button_text || 'Buy It Now'}</a>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false
                })
                console.log(this.popup)
            } else {
                if(this.popup) this.popup.close()
            }
        }
    },
    methods: {
        displayNameUpdated( e ) {
            console.log('name updated: ', e.target.value, e.target.dataset.id)
            saveStatus({
                id: e.target.dataset.id,
                display_name: e.target.value
            })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
        },

        scoreUpdated( e ) {
            console.log('score updated: ', e.target.value, e.target.dataset.id)
            if(this.throttle) clearTimeout(this.throttle)
            this.throttle = setTimeout(() => saveStatus({
                id: e.target.dataset.id,
                score: e.target.value
            })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            }), 500)
        }
    }
}
</script>

<style lang="scss">
    .leaderboard-active {
        input {
            border-width: 1px;
        }
    }

    .interesante {
        width: 100%;
        height: 86vh;
        position: absolute;
    }
</style>